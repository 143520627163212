import React from 'react';

import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';

import property from '../../img/webhook/Choose-property.png';
import settings from '../../img/webhook/Go-to-settings.png';
import settingsWebhook from '../../img/webhook/Select-webhooks.png';
import webhookInput from '../../img/webhook/Put-your-webhook-URL.png';

import './styles.scss';

const WebhookIntegration = () => {
  return (
    <div className="webhook-main">
      <Layout>
        <PageHero title="Webhook integration" />
        <Section>
          <div className="col-12 d-block mb_20 webhook-doc" style={{maxWidth: '1200px', width: '100%'}}>
            <h5>Follow the steps for Webhook connection</h5>
            <ol>
              <li>
                <p>Choose property</p>
                <img src={property} alt="" style={{width: '100%'}} />
              </li>
              <li>
                <p>Go to settings</p>
                <img src={settings} alt="" style={{width: '100%'}} />
              </li>
              <li>
                <p>Select webhooks</p>
                <img src={settingsWebhook} alt="" style={{width: '100%'}} />
              </li>
              <li>
                <p>Put your webhook URL in the webhook field</p>
                <img src={webhookInput} alt="" style={{width: '100%'}} />
              </li>
            </ol>
            <br />

            <h6>
              Every time a Task/Issue is created on your property in Hexometer, a JSON formatted data will be sent to
              your provided webhooks
            </h6>
            <div className="json-title-block">
              <span>JSON representation</span>
            </div>
            <pre className="pre">
              <p>{'{'}</p>
              <p className="mgl-25">"id": "unique id of the Task/issue",</p>
              <p className="mgl-25">"property": "property address",</p>
              <p className="mgl-25">"date": "Date of creation"</p>
              <p className="mgl-25">"issue_type": "Type of Task/issue"</p>
              <p className="mgl-25">"toolName": "Tool that created the Task/issue",</p>
              <p className="mgl-25">"level": "Level of the Task/issue",</p>
              <p>{'{'}</p>
            </pre>
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default WebhookIntegration;
